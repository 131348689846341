<template>
  <div class="user-list">
    <!-- <div class="search-form">
      <a-form-model
        layout="inline"
        :model="searchForm"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="关键词：">
          <a-input v-model="searchForm.keyword" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="分类：">
          <a-select
            v-model="searchForm.category"
            placeholder="请选择"
            style="width: 180px"
          >
            <a-select-option
              v-for="(cate, i) in categoryOptions"
              :key="i"
              :value="cate.value"
            >
              {{ cate.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit"> 搜索 </a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="default" @click="onResetClick"> 重置 </a-button>
        </a-form-model-item>
      </a-form-model>
    </div> -->
    <div class="table">
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        :rowKey="(row, index) => index"
        @change="onTableChange"
      >
        <template slot="index" slot-scope="text, record, index">
          {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
        </template>
        <template slot="available" slot-scope="text">
          <a-tag v-if="text" color="#87d068">正常</a-tag>
          <a-tag v-else color="#f50">已禁用</a-tag>
        </template>
        <template slot="action" slot-scope="text, record">
          <!-- <a-button type="link" @click="onDetailClick(record)">详情</a-button> -->
          <!-- <a-button type="link" @click="onDisableClick(record)">禁用</a-button> -->
          <a-switch
            v-model="record.available"
            checked-children="启用"
            un-checked-children="禁用"
            default-checked
            @change="onStatusChange(record)"
          />
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

const columns = [
  {
    key: 'index',
    title: '序号',
    scopedSlots: { customRender: 'index' },
  },
  {
    dataIndex: 'pi_username',
    title: 'Pi用户名',
    customRender: (text) => {
      return `@${text}`;
    },
  },
  {
    dataIndex: 'language',
    title: '语言',
  },
  {
    dataIndex: 'available',
    title: '是否可用',
    scopedSlots: { customRender: 'available' },
  },
  {
    dataIndex: 'login_at',
    title: '最后登录时间',
    customRender: (text) => {
      return dayjs(text).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  {
    dataIndex: 'create_at',
    title: '注册时间',
    customRender: (text) => {
      return dayjs(text).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  {
    key: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];

export default {
  name: 'UserList',
  components: {},
  data() {
    return {
      columns,
      // searchForm: {
      //   keyword: '',
      //   category: undefined,
      // },
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
    };
  },
  computed: {},
  mounted() {
    this.getList();
  },
  methods: {
    handleSubmit() {
      this.getList();
    },
    async getList() {
      const { current, pageSize } = this.pagination;
      this.loading = true;
      const { err, res } = await this.$api.user.list({
        pageIndex: current - 1,
        pageSize,
      });

      if (!err) {
        this.data = res.result.list;
        this.pagination.total = res.result.total;
      }
      this.loading = false;
    },
    // onResetClick() {
    //   this.$data.searchForm = this.$options.data().searchForm;
    //   this.$data.pagination = this.$options.data().pagination;
    //   this.getList();
    // },
    onTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.getList();
    },
    // onDetailClick(record) {
    //   this.$refs.detailDrawer.open(record);
    // },
    // onDisableClick(record) {
    //   console.log(record);
    // },
    async onStatusChange(record) {
      const { err, res } = await this.$api.user.disableEnable({
        _id: record._id,
      });

      if (!err) {
        this.$message.success(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  // margin-top: 20px;
}
</style>
